/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/assets/style/layout.css"
import { init, setDeviceId } from "@amplitude/analytics-browser"
import uuid from "react-uuid"

import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { AuthProvider } from "./src/context/userContext"

setDeviceId(uuid())
init("6fa413079bc2603e1d75b73eb0f3e46d", undefined, {
  disableCookies: true, // Disables the use of browser cookies
  // serverZone: amplitude.Types.ServerZone.EU,
  trackingOptions: {
    carrier: false,
    device_model: false,
    dma: false,
    ip_address: false,
    os_name: false,
    os_version: false,
    version_name: false,
  },
})

localStorage.removeItem("theme-ui-color-mode")

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6Lfb1fwcAAAAADmyEPNghVeB3dowQdIP23wDh_G2">
    <AuthProvider>{element}</AuthProvider>
  </GoogleReCaptchaProvider>
)
