import React, { useEffect, useState } from "react"
import supabase from "../services/supabase"
import { navigate } from "gatsby"
export const UserContext = React.createContext()
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      // console.log(event, session)
      if (session) {
        setUser(session.user)
      } else {
        setUser(null)
      }
      if (event === "USER_UPDATED") {
        navigate("/private/dashboard/", {
          replace: true,
        })
      }
    })
  }, [])
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  )
}
